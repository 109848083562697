<template>
  <div>
    <standbyDialog :localLoadingDialog="localLoadingDialog"></standbyDialog>
    <primaryButton
      class="mr-2"
      :attributesArray="functionElement['attributesArray']"
      type="button"
      :value="functionElement['functionName']"
      @click="$emit('editGraph', result.valueLine)"
      :label="functionElement['functionName']"
      color="save"
      :disabled="loading"
      :loading="loading"
      icon="mdi-content-save"
      :shrink="functionElement['shrink']"
      :id="id"
    ></primaryButton>
  </div>
</template>
<script>
import primaryButton from "@/commonComponents/primaryButton.vue";
import standbyDialog from "@/commonComponents/standbyDialog.vue";
export default {
  props: ["isValid", "loading", "functionElement", "result","id"],
  components: {
    primaryButton,
    standbyDialog,
  },
  data() {
    return {
      localLoadingDialog: false,
    };
  },
};
</script>